<!-- 编辑弹窗 -->
<template>
  <div>
    <a-modal :width="950" :visible="visible" :confirm-loading="loading" :title="isUpdate ? '修改' : '新建'"
      :body-style="{ paddingBottom: '8px' }" @update:visible="updateVisible" @ok="save">
      <a-form ref="form" :model="form" :rules="rules" :label-col="{ md: { span: 5 }, sm: { span: 24 } }"
        :wrapper-col="{ md: { span: 19 }, sm: { span: 24 } }">
          <a-row :gutter="16">
              <a-col :sm="24" :xs="24">
                <a-form-item label="模块名称:" name="formName">
                  <a-input v-model:value="form.formName" placeholder="请输入模块名称" allow-clear />
                </a-form-item>
              </a-col>
          </a-row>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import * as activityFormApi from '@/api/activity/activityForm.js'
export default {
  name: 'formInfoEdit',
  emits: ['done', 'update:visible'],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: {},
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 编辑弹窗表单验证规则
      rules: {
        formName: [
          {
            required: true,
            message: '请输入活动名称',
            type: 'string',
            validateTrigger: 'blur'
          }
        ]
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false
    }
  },
  watch: {
    data() {
      if (this.data) {
        this.form = this.data
        this.isUpdate = true
      } else {
        this.form = {}
        this.isUpdate = false
      }
    }
  },
  mounted() {
  },
  methods: {
    resetForm() {
      this.form = {}
    },
    /* 保存编辑 */
    save() {
      this.$refs.form
        .validate()
        .then(() => {
          this.loading = true
          activityFormApi
            .save(this.form, this.isUpdate)
            .then((res) => {
              this.loading = false
              if (res.code === 0) {
                this.$message.success(res.msg)
                if (!this.isUpdate) {
                  this.resetForm()
                }
                this.updateVisible(false)
                this.$emit('done')
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((e) => {
              this.loading = false
              this.$message.error(e.message)
            })
        })
        .catch(() => { })
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    }
  }
}
</script>

<style lang="less" scoped>
.flex-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.author-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #d6d7d9;
  margin: 20px 0;
  padding: 10px 20px;

  .author-content {
    width: 800px;

    .author-item {
      display: flex;
      width: 100%;
      margin-bottom: 10px;

      .value {
        width: 80%;
        white-space: wrap;
        color: #9b9b9b;
      }
    }
  }

  .title {
    display: inline-block;
    width: 40px;
    margin-right: 5px;
  }
}
</style>
